<template>
    <v-card>
        <v-toolbar color="grey darken-3" dark elevation="1">
            <v-toolbar-title>
                {{ title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu offset-y v-model="menu">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                            class="grey darken-3"
                            icon
                            outlined
                            :disabled="isNew"
                            v-bind="attrs"
                            v-on="on"
                            :loading="getModalLoading"
                            @click="menu = true"
                    >
                        <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
                    </v-btn>
                </template>
                <v-list dense rounded nav>
                    <v-list-item link class="px-4" @click.stop="deleteDialog">
                        <v-list-item-icon>
                            <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-toolbar>
        <v-card-text class="pt-4">
            <v-form v-model="formValid" ref="form">
                <v-row>
                    <v-col cols="12" md="12">
                        <v-text-field type="text" filled label="Назва показника" v-model="name"
                                      hide-details
                                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                      :class="name ? '' : 'req-star'"
                                      color="grey"/>
                    </v-col>
                    <v-col cols="12">
                        <AC_UnitType :value="unit_type_id" @autocompleteChange="flatIndicatorTypeChange"
                                     :class="unit_type_id ? '' : 'req-star'" color="grey"/>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed text color="secondary darken-1" @click.stop="crud_item" :loading="getModalLoading"
                   class="button-accept">
                <v-icon left>mdi-content-save</v-icon>
                Зберегти
            </v-btn>
            <v-btn depressed text tile color="grey" @click="closeModal">
                <v-icon left>mdi-close</v-icon>
                Відмінити
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import {
    CREATE_FLAT_INDICATOR_TYPE,
    REMOVE_FLAT_INDICATOR_TYPE,
    UPDATE_FLAT_INDICATOR_TYPE,
} from "@/store/actions/flat_indicators";
import {mapGetters} from 'vuex'
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'flat_indicator_type_modal_delete'

export default {
    name: "HWP_Modal_FlatIndicatorType",
    mixins: [ModalComponentMixin],
    components: {
        AC_UnitType: () => import("@/components/autocomplite/AC_UnitType")
    },
    data() {
        return {
            name: this.item.name,
            unit_type_id: this.item.unit_type_id,
        }
    },
    methods: {
        flatIndicatorTypeChange(payload) {
            this.unit_type_id = payload?.value || null
        },
        closeModal() {
            this.$emit('closeModal')
            this.itemId = this.item.id
            this.name = this.item.name
            this.unit_type_id = this.item.unit_type_id

            if (this.$refs.form) {
                this.$refs.form.resetValidation()
            }
        },
        deleteDialog() {
            this.menu = false
            const payload = {
                text: `Підтвердіть вилучення виду якісного показника ${this.name}`,
                accept_button: true,
                id: modalDeleteId
            }
            this.$store.dispatch(QUESTION_SHOW, payload)
        },
        crud_item() {
            this.formValid = this.$refs.form.validate()
            if (!this.formValid) {
                this.formValid = false
                this.$store.commit(ALERT_SHOW, {
                    text: 'Назва має бути заповнена',
                    color: 'error lighten-1'
                })
                return
            }

            const payload = {
                name: this.name,
                unit_type_id: this.unit_type_id
            }

            if (this.isNew) {
                this.$store.dispatch(CREATE_FLAT_INDICATOR_TYPE, payload)
                    .then(el => {
                        if (el) {
                            this.closeModal()
                        }
                    })
            } else {
                payload.id = this.itemId
                this.$store.dispatch(UPDATE_FLAT_INDICATOR_TYPE, payload)
                    .then(el => {
                        if (el) {
                            this.closeModal()
                        }
                    })
            }
        },
        watch_item() {
            this.watcher = this.$watch(
                'item',
                {
                    deep: true,
                    immediate: true,
                    handler(payload) {
                        this.itemId = payload.id
                        this.name = payload.name
                        this.unit_type_id = payload.unit_type_id
                    }
                }
            )
        },
        watch_modal_answer() {
            this.delete_watcher = this.$watch(
                'modalAnswer',
                {
                    handler(payload) {
                        if (payload.id === modalDeleteId) {
                            if (payload.answer) {
                                this.$store.dispatch(REMOVE_FLAT_INDICATOR_TYPE, this.itemId)
                                    .then(ok => {
                                        if (ok) {
                                            this.closeModal()
                                        }
                                    })
                            }
                        }
                    }
                }
            )
        },
        watch_dialog() {
            this.dialog_watcher = this.$watch(
                'dialog',
                {
                    immediate: true,
                    handler(payload) {
                        if (payload) {
                            this.watch_item()
                            this.watch_modal_answer()
                        } else {
                            if (this.watcher) {
                                this.watcher()
                            }
                            if (this.delete_watcher) {
                                this.delete_watcher()
                            }
                        }
                    }
                }
            )
        }
    },
    computed: {
        ...mapGetters({
            unit_select: 'getUnitTypesSelect',
        }),
    },
}
</script>
